@import 'src/style/global';

.VehicleUnavailabilityModal {
  .error {
    padding-top: 20px;
    color: $error-color;
  }

  .form {

    .Select select{
      padding: 10px 16px;
    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .row-1 {

      .row-w {
        width: 40%;
      }

      .row-w:nth-child(2) {
        width: 20%;
        margin-left: 20px;
      }

      .height {
        padding: 2px;
      }
    }

    .row-2 {
      width: 100%;

      .row-fw {
        width: 100%;
      }
      .row-w {
        width: 40%;
      }

      .row-w:nth-child(2) {
        width: 20%;
        margin-left: 36%;
      }

      .divider {
        width: 5%;
        margin-top: 45px;
        margin-left: 10px;
      }

    }

    .row-3 {
      width: 100%;
      display: flex;
      flex-direction: row;

      .row-w {
        width: 100%;
        margin:0 15px 0 0;
      }
    }
  }
}

@import '../../../style/global';

.MonthlyOverloadedTable {
  .overloaded-entry {
    float: left;
    background: $em_background;
    border-radius: $label-border-radius;
    margin: 0 0 2px 1px;
    border: solid 1px $success-color;
    padding: 2px 10px;
    color: $success-color;
    cursor: pointer;
  }
}

@import 'src/style/global';

.ModifyEntryModal {
  background: white;
  padding: 17px 44px;
  @include modalWidth();
  border-radius: 8px;
  z-index: 1001;
  max-height: 80%;
  min-height: 200px;

  .ConfirmModal-Body {
    max-height: 350px;
    overflow-y: auto;
  }

  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  &-Buttons {
    margin-top: 20px;
    float: right;

    button {
      margin: 0 0 0 15px;
    }
  }
}

.ModifyEntryModal-Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "../../../style/global";

.HeaderSection {
  margin-top: 2%;
  display: flex;
  flex-direction: column;

  .bold {
    font-weight: bold;
  }

  .circuit {
    margin: 10px 0 0 0;
  }

  .actionButton {
    margin: 9px 35px 0 0;
  }

  .registrySubtitle {
    flex-direction: row;
    display: flex;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 35%;
    }

    .side-subtitle {
      margin-top: 5px;
    }

  }

  .details {
    margin-top: 10px;
    .row {
      display: flex;

      div {
        width: 50%;
      }
    }
  }
  .registryDetails {
    margin-top: 10px;
  }

  .subtitle {
    width: 100%;
    font-family: Roboto, serif;
    color: $font-dark-color2;
    fill: $font-dark-color2;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
  }

  .row-content {
    font-weight: bold;
  }

  .vehicleDetails {
    h2 {
      margin-top: 0;
    }
    width: 100%;
    .row {
      display: flex;
      margin: 10px 0 0 0;
      div {
        width: 25%;
        text-align: center;
      }
    }
  }
}

.SearchInput {
  padding: 10px 0;

  input {
    height: 40px;
    margin: 0;
    border-radius: 6px;
    background-color: white;
    border: none;
    outline: none;
    display: block;
    padding: 10px;
    width: 100%;
  }
}

@import '../../style/global';

.TextInput {
    &-label{
      font-size: $font-size;
      color: $font-color;
      display: block;
      float: left;
    }

    &-tooltip {
      float: right;
      margin: 10px;
    }

  input {
    height: 40px;
    margin: 0;
    border-radius: 6px;
    background-color: #f3f6f8;
    border:none;
    outline: none;
    padding: 16px;
    display: block;
  }

  &-error{
    font-size: $font-size;
    color: $error-color;
    display: block;
    position: relative;
    //top: -20px;
  }

  input::-moz-placeholder{
    color: $font-placeholder-color;
  }

  input:-ms-input-placeholder{
    color: $font-placeholder-color;
  }

  input::placeholder{
    color: $font-placeholder-color;
  }

  .TextInput-borderError {
    border: solid 2px $error-color;
  }
}


@import '/src/style/global';

.InfoBar {
  padding: 5px;
  border-radius: 5px;

  &.alert {
    background-color: $background-soft-color;
  }

  &.warning {
    background-color: $em_plus_background;
  }

  .icon-warning {
    float: left;
    margin: 0 15px 0 0;
  }
}

@import "../../../style/global";

.FixedShiftSection {
  display: flex;
  flex-direction: column;


  .registryDetails {

  }
  .contractSubtitle {
    .subtitle {
      float: left;
      @include big-medium-dark-text();
    }
  }

  .actionButton {
    float: right;
  }

  .contractDetails {
    width: 100%;
    display: flex;
    flex-direction: column;

    .row {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .lbl {
        width: 33%;
        float: left;
        flex-grow: 1;

        label {
          @include default-text();
        }

        .value {
          margin-top: 10px;
          @include default-bold-text();
        }
      }
    }
  }

  .details {

    .registrySubtitle {
      .subtitle {
        float: left;
        @include big-medium-dark-text();
      }
    }

    .registryDetails {
      width: 100%;
      float: left;
      display: flex;
      flex-direction: column;

      .entry {
        width: 25%;
        margin: 0 2.5%;
        float: left;

        .shift-name {
          text-transform: uppercase;
          font-weight: bolder;
        }
      }
    }

    .row {
      margin-top: 20px;
    }

    .lbl {
      float: left;

      .value {
        margin-top: 10px;
        @include default-bold-text();
      }
    }
  }
}

@import "../../style/global";


.canvas {
  position: relative;
}

body {
  overflow-y: scroll;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;



  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */

  padding-bottom: 70px;
}

html { box-sizing:border-box }
// *, *:before, *:after { box-sizing:inherit }



.container-vehicle-list {
  min-width: 960px;
  overflow: hidden;

  /*touch-action: none;*/
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px;
  min-width: 350px;
}

.modal-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  padding-top: 15px;

  .Select {
    display: flex;
    justify-content: stretch;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;


  }

}

.modal-row.buttons {
  justify-content: flex-end;

  Button {
    margin-left: 10px;
  }
}

.modal-row label {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > * {
    flex-grow: 1;
    flex-shrink: 1;
  }
  span {
    margin-right: 10px;
    flex-grow: 0;
    text-align: right;
  }
}

.time-inputs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  .time-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-size: 0.9rem;
    }
  }
}

.canvas .toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-right: 45px;

  .circuit-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;

    .TimeLineNavigation {
      flex-grow: 1;
      flex-shrink: 1;
      width: unset;
      align-items: center;
      justify-content: center;
      .TimeLineNavigation-buttons {
        flex-grow: 0;
      }
    }
    
    .add-need-button {
      flex-basis: 56px;
      flex-grow: 0;
      flex-shrink: 0;
      background-color: #0572ce;
      border-radius: 8px;
      color: white;
      border: none;
      font-size: 20px;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: -3px;
      display: flex;
      align-items: center;
      justify-content: center;

      box-shadow: 0 2px 3px 0 rgba(141, 161, 176, 0.32);
      background-color: #AAAAAA;
      margin-bottom: -3px;
      position: relative;
      z-index: 5;


      &:hover {
        background-color: #888888;
      }
      
      &:active {
        background-color: #888888;
        transform: translateY(1px);
      }
    }

    .circuit {
      flex-basis: 200px;
      height: 53px;
      border-radius: 8px;
      box-shadow: 0 2px 3px 0 rgba(141, 161, 176, 0.32);
      background-color: #ffffff;
      margin-bottom: -3px;
      position: relative;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: stretch;
      margin-right: 10px;
      padding-right: 10px;
      flex-grow: 0;
      flex-shrink: 0;

      > * {
        flex-grow: 1;
        flex-shrink: 1;
      }

      select {
        background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-position: 100% 50%;
        background-repeat: no-repeat;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
      }
    }
  }
}


.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  -webkit-animation: indeterminateAnimation 1s infinite linear;
          animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

@-webkit-keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

@import '../../../style/global';

.TimeLineDataRow{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ccc;
  position: relative;
  overflow: hidden;

  &-line {
    font-family: $font-family-base;
    position: absolute;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    color: $text-color;
    border-radius: 8px;
    background-color: #c7e4f9;
    z-index: 2;
  }
}

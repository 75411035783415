@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
@import "palette";

$font-family-base: Roboto, Verdana, Geneva, sans-serif;

@mixin default-text() {
  font-family: Roboto, serif !important;
  color: $text-color !important;
  fill: $text-color !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

@mixin big-medium-dark-text() {
  font-family: Roboto, serif;
  color: $font-dark-color;
  fill: $font-dark-color;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

@mixin bigger-bold-dark-text() {
  font-family: Roboto, serif;
  color: $font-dark-color;
  fill: $font-dark-color;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
}

@mixin default-medium-text() {
  font-family: Roboto, serif;
  color: $text-color;
  fill: $text-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

@mixin default-bold-text() {
  font-family: Roboto, serif;
  color: $text-color;
  fill: $text-color;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
}

@mixin bigger-bold-dark-text2() {
  font-family: Roboto, serif;
  color: $font-dark-color2;
  fill: $font-dark-color2;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
}

@mixin small-text() {
  font-family: Roboto, serif;
  color: $text-color;
  fill: $text-color;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

@import '../../style/global';

$length: 419px;

.SideBar {
  position: fixed;
  width: 100%;
  max-width: $length;
  right: -$length;
  background: white;
  height: 100%;
  top: 0;
  box-shadow: -3px 4px 11px 0 #dae4f0;
  padding: 0 17px 59px 15px;
  overflow-y: scroll;

  h2 {
    font-size: 18px;
  }

  &.open {
    right: 0;
  }

  .close {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    cursor: pointer;
  }

  .actionButton {
    @include default-medium-text();
    color: #09a7ef;
    cursor: pointer;
  }

  .actionButton {
    float: right;
  }
}


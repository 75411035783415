@import '/src/style/global';

.WeekToggleGroup {

  .dist {
    width: 100%;
    margin: 0 43px 15px 0;

    input {
      width: 119px;
    }
  }

  .column-time-range{
    width: 50%;
    display: flex;
  }
}

@import '../../style/global';

.Button {
  padding: 5px 5px;
  border-radius: 6px;
  background-color: $primary-color;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;

  &.secondary {
    background: white;
    color: $primary-color;
    border: 2px solid $primary-color;
  }

  &.flat {
    background: transparent;
    color: $primary-color;
    border: 0;
    padding: 0;
    font-weight: bold;
  }

  &.error {
    color: #df5858;
    font-weight: bold;
  }

  &.disabled{
    opacity: 0.5;
    cursor: default;
  }
}
@include for-tablet-portrait-up {
  .Button {
    padding: 7px 20px;
  }
}

@include for-desktop-up {
  .Button {
    padding: 9px 35px;
  }
}

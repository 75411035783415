@import '../../style/global';

.Legend {
  display: flex;
  align-items: center;
  &-item {
    margin:0 20px;
    display: flex;
    align-items: center;
  }

  &-sample {
    border:1px solid $font-color;
    height: 20px;
    width:20px;
    margin-right: 10px;
  }

  &-label {

  }
}


.VehicleManagement {

  .CircuitSelect select {
    background-color: white;
    height: 40px;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .up-controls {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .Legend{
      margin:20px
    }

    .Button{
      margin: 10px;
    }

    .execution-details {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-end;
      margin:20px
    }

    .result-icon{
      margin-left:10px;
      display: inline;
    }

  }

  .DailyEmployeeLine {
    cursor: pointer;
  }

  div.planning-panel .FocusPanel {
    top: 22%;
    background: rgba(255, 255, 255, 0.3);
  }
}



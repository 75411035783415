@import "palette";
@import "fonts";
@import "main";


.skillLabel {
  margin: 0 0 2px 1px;
  padding: 1px 5px;
  float: left;
  border-radius: 3px;
  @include big-medium-dark-text();
  font-size: 14px;
}

.actionable {
  cursor: pointer;
}

.EM_PLUS {
  background: $em_plus_background;
  color: $em_plus;
  border: solid 1px $em_plus;
}

.EM {
  background: $em_background;
  color: $em;
  border: solid 1px $em;
}

.INTERH {
  background: $interh_background;
  color: $interh;
  border: solid 1px $interh;
}

.INTRAH {
  background: $intrah;
  color: $font-dark-color2;
  border: solid 1px $font-dark-color2;
}

.MOSS {
  background: $moss;
  color: $font-dark-color2;
  border: solid 1px $error-color;
  color: $error-color;
}

$max-height-timeline: calc(100vh - 332px);




.WizardStepTwo {

  display: flex;
  flex-direction: column;
  &-navigation {
    width: 100%;
    float: left;
    color: #09a7ef;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &-actionBar{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .SearchInput{
      min-width: 400px;
    }
    .Button{
      margin: 10px;
    }

    &-separator{
      flex-grow: 1;
    }
  }
}

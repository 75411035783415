@import "../../style/global";

.search-result {
  margin: 20px 0;
}

.search-location {
  margin: 20px 0;
}

.Dashboard h1 {
  float: left;
}

.Dashboard .up-controls {
  width: 60%;
  float: left;
  margin-top: 15px;

  button {
    float: right;
    margin: 0 0 0 10px;
  }
}

.Dashboard .up-title {
  width: 40%;
  float: left;
}

.TypologyContainer {
  display: flex;
  flex-wrap: wrap;
  width: 175px;
  justify-content: space-between;

  .Typology {
    @include default-bold-text();
    text-transform: uppercase;
    border-radius: 8px;
    background-color: $typology-color;
    padding: 5px 10px;
  }
}

.DescriptionContainer {
  width: 180px;
  display: flex;
  flex-direction: row;

  .desc-w {
    width: 90%;
  }
  .actionButton {
    cursor: pointer;
    width: 10%;
  }
}

.PauseContainer{
  margin-left: 10px;
}

@import 'src/style/global';

.Modal {
  background: white;
  padding: 10px 15px 15px 15px;
  border-radius: 8px;
  z-index: 11;
  /*max-height: 90%;*/
  /*overflow-y: auto;*/
  @include center();

  .Button.add-btn {
    float: right;
    width: 138px;
    margin: 20px 0 0 0;
  }

  .Button.cancel-btn {
    float: right;
    width: 138px;
    margin: 20px 15px 0 0;
  }

  .buttons {
    min-height: 50px;
  }

  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  .TextInput {
    input {
      width: 100%;
    }

    &-label {
      margin: 10px 0;
    }
  }
}

@include for-tablet-portrait-up {
  .Modal{
    @include modalWidth();
    padding: 17px 44px 44px 44px;
  }
}

@import '../../style/global';

.Select {

  select {
    width: 100%;
    min-height: 40px;
    margin: 0;
    border-radius: 6px;
    background-color: #f3f6f8;
    border:none;
    outline: none;
    padding: 16px;
    display: block;
  }

  &-error{
    font-size: $font-size;
    color: $error-color;
    display: block;
    position: relative;
    top: -20px;
  }

  select::-moz-placeholder{
    color: $font-placeholder-color;
  }

  select:-ms-input-placeholder{
    color: $font-placeholder-color;
  }

  select::placeholder{
    color: $font-placeholder-color;
  }

  .Select-borderError {
    border: solid 2px $error-color;
  }

/*  @include for-tablet-landscape-up {
    select {
      min-height: 35px;
    }
  }

  @include for-desktop-up {
    select {
      min-height: 40px;
    }
  }*/
}



.Pagination {
  display: flex;

  li {
    text-align: center;
    list-style-type: none;
    height: 25px;
    line-height: 25px;
    padding: 0 6px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    &.selected {
      border-radius: 3px;
      background-color: #09a7ef;
      color: #ffffff;
    }

    &.previous {
      &.disabled {
        opacity: 0.5;
      }
    }

    &.next {
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}

.Pagination-Container {
  display: flex;
  align-items: center;
  justify-content: center;
}





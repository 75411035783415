@import "../../../style/global";

.SkillModal {

  .skill-row {
    width: 100%;
    float: left;
  }

  .skill-label {
    float: left;
  }

  .skill-input {
    float: right;
  }

  .skill-row:last-child {
    margin-top: 20px;
  }

  .tutoring-section {
    float: left;
    width: 100%;
    margin: 15px 0 15px 0;

    span {
      width: 100%;
      float: left;
    }
  }

  .error {
    color: $error-color;
  }
}

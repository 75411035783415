@import '../../../style/global';

.MonthlyPlanningNoData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: calc(100vh - 272px);
  background-color: #ffffff;

  &-wrapper{
    width: 100%;
    color: #8da1b0;
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  &-title{
    @include bigger-bold-dark-text();
    margin: 10px;
    text-align: center;
  }
}

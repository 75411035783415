@import 'src/style/global';


.ModifyWorkerContractModal {
  background: white;
  padding: 10px 15px 10px 15px;

  border-radius: 8px;
  z-index: 1;
  @include center();

  .Title {
    @include bigger-bold-dark-text2();
  }

  .Content {


    height: 390px;
    position: relative;
    .contractContainer {
      height: 100%;
      position: absolute;
      left: 0;
      width: 520px;
      overflow-y: auto;
      overflow-x: hidden;
      .contracts {
        width: 460px;
        position: absolute;
        left: 0;
        .Contract {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-top: 35px;

          .close {
            position: absolute;
            right: -30px;
            top: 50%;
            z-index: 1;
            cursor: pointer;
          }

          .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .TextInput {
              width: 140px;

              &-label {
                margin: 10px 0;
              }
            }

            .DatePicker {
              width: 144px !important;

              .react-date-picker {
                width: 100% !important;
              }

              .react-date-picker__calendar {
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%);
                position: fixed !important;
              }
            }
          }
        }
      }
    }
  }

  .Buttons {
    min-height: 50px;
    margin-top: 50px;
    position: relative;

    .Button.add-btn {
      right: 0;
      width: 138px;
      height: 35px;
      margin: 0;
      @include center-vertically();
    }

    .Button.cancel-btn {
      right: 138px;
      width: 138px;
      height: 35px;
      margin: 0px 15px 0 0;
      @include center-vertically();
    }

    .addEmptyContract {
      float: left;
      @include default-medium-text();
      @include center-vertically();
      color: $primary-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .icon {
        font-size: 24px;
        margin-right: 5px;
      }
    }
  }
}

@include for-tablet-portrait-up {
  .ModifyWorkerContractModal{
    min-width: 590px;
    padding: 30px 50px 35px 50px;
  }
}

@import '../../../../style/global';
$okColor: #b0da72;

.DailyEmployeeCell{
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  /*align-items: end;*/
  justify-content: start;
  height: 100%;
  position: relative;

  .otherServices {
    float:left;
    margin: 5px 0 0 2px;
    position: relative;
  }

  &-service{
    font-size: 12px;
    margin: 5px 0 0 0 !important;
    float: left !important;
  }

  &-skill{
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 12px !important;
  }

  &-name{
    font-size: 13px;
    font-weight: 500;
    color: $text-color;
    padding-top: 2px;
    max-width: 65%;
    overflow: hidden;
    white-space: nowrap;
    min-height: 18px;
    text-overflow: ellipsis;
  }

  &-IconTrash{
    position: absolute;
    right:18px;
    top:18px;
  }


  &-role{
    margin-left:5px;
    font-size: 12px;
    color: #919da5;
    text-transform: lowercase;

    &.nurse {
      color:$error-color;
    }
    &.driver {
      color:$primary-color;
    }

  }

  .free {
    background: #ebf6da;
    border-radius: 4px;
    margin: 0 0 2px 1px;
    border: solid 1px $okColor;
    padding: 2px 10px;
    color: $okColor;
  }

  .rest{
    border-radius: 4px;
    margin: 0 0 2px 1px;
    padding: 2px 10px;
    background: #c6ecfd;
    color: #09a7ef;
    border: solid 1px #09a7ef;
  }

  .vacation{
    border-radius: 4px;
    margin: 0 0 2px 1px;
    padding: 2px 10px;
    background: #e8f3ff;
    color: #424d54;
    border: solid 1px #424d54;
  }

  .busy {
    $okColor: $error-color;
    background: #ffd1d1;
    border-radius: 4px;
    margin: 0 0 2px 1px;
    border: solid 1px $okColor;
    padding: 2px 10px;
    color: $okColor;
  }

  .other {
    $errorColor: #ffca8c;
    background: #fff2e8;
    border-radius: 4px;
    margin: 0 0 2px 1px;
    border: solid 1px $errorColor;
    padding: 2px 10px;
    color: $errorColor;
  }


}

@import 'src/style/global';

$size: 32px;

.ToggleDayButton, .ToggleDayButton:hover, .ToggleDayButton:active {
  width: $size;
  height: $size;
  border-radius: 16px;
  background: $background-soft-color;
  position: relative;
  padding: 0;
  font-size: 12px;

  .label {
    position: absolute;
    color: black;
    left: 50%;
    margin-left: -30%;
    top: 50%;
    margin-top: -20%;
  }
}

.selected {
  background: $error-color;

  .label {
    color: white;
  }
}

.selected:active {
  background: $background-soft-color;
  color: black;
}

.selected:hover {
  background: $error-color;
  color: white;
}

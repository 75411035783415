/* Body */

*, *:before, *:after{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html, body {
  font-family: $font-family-base;
  color: $font-color;
  background-color: #f3f6f8;
  margin:0;
  padding:0;
  //height: 100vh;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 18px;
}

.TextInput-borderError {
  border: solid 2px $error-color;
  border-radius: 5px;
}

.above-title {
  width: 100%;
  min-height: 10px;
}

.Dashboard .up-title {
  width: 40%;
  float:left;
}

.modal {
  z-index: 100;
}



@mixin for-phone-only {
@media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
@media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
@media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
@media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
@media (min-width: 1800px) { @content; }
}





@mixin center-vertically() {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@mixin center-horizontally() {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

$tooltip-width: 200px;
$tooltip-border-radius: 10px;
$tooltip-arrow-width: 25px;
$tooltip-arrow-height: 10px;
$vertical-standard-padding: 10px;
$horizontal-standard-padding: 10px;

.inner-content {
  margin:0;
}

@include for-tablet-portrait-up {
  .inner-content {
    margin:0 10px;
  }
}

@include for-tablet-landscape-up {
  .inner-content {
    margin:0 20px;
  }
}

@include for-desktop-up {
  .inner-content {
    margin:0 30px;
  }
}

@include for-big-desktop-up {
  .inner-content {
    margin:0 80px;
  }
}

@mixin center() {
  position: fixed;

  @include for-phone-only {
    width: 100%;
    height: 100%;
    inset:0;
  }

  @include for-tablet-portrait-up {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin modalWidth(){
  width: 100%;
  max-width: 768px;
}

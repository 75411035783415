@import '../../../../style/global';

.bg-gray {
  background-color: $color-pale-grey !important;
}

.DailyVehicleCell {
  cursor: pointer;

  .DailyServiceCell-serviceType {
    float:left;
  }

  &-machineType {
    padding: 1px 5px;
    border-radius: 4px;
    border: solid 1px #b0da72;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #b0da72;
  }

  &-machineName {
    font-size: 16px;
    font-weight: 200;
    color: $font-dark-color2;
  }

  .vehicleIcon-Name {
    .IconVehicle {
      float: left;
      margin: 0 10px 0 0;
    }

    .name {
      margin: 0px 0 0 0;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


}

@import '/src/style/global';

.MonthlyPlanningViewSelector {



  .MonthlyPlanning-actions {
    display:flex;
    button {
      overflow: hidden;
      white-space: nowrap;
      //text-overflow: ellipsis;
      margin: 0;
      border: 2px solid $primary-color;
      border-radius: 0;
    }

    button:nth-child(1) {
      border-radius: 5px 0 0 5px;
    }

    button:nth-child(2) {
      border-left: 0;
      border-right: 0;
    }

    button:nth-child(3) {
      border-radius: 0 5px 5px 0;
    }

    @include for-tablet-portrait-up {
      button {
        max-width: initial;
      }
    }
  }
}

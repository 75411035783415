@import '../../../../style/global';

.VehicleHeader {
  &-text {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: $text-color;
  }

  &-subtext {
    font-size: 12px;
    text-align: center;
    color: #8da1b0;
  }
}

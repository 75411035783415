@import '/src/style/global';

.MonthlyTimeLineButtonBar {
  display: flex;
  justify-content: flex-end;
  min-height: 60px;
  width: 100%;

  .circuit-select select {
    padding: 0 10px;
    /*width: 33%;
    float: left;*/
    background-color: white;
    margin: 0 10px;
  }

  &-row {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;

/*    button.modify-btn {
      margin: 10px 20px 0 0;
    }*/

    .downloadExcel-btn {
      margin: 0 0 0 10px;
      height: 30px;
      overflow: hidden;
    }

    @include for-tablet-portrait-up {
      .downloadExcel-btn {
        margin: 0 0 0 10px;
        width: initial;
        height: initial;
        overflow: initial;
      }
    }
  }

  &-leftSection{
    display: flex;
    align-items: center;
  }

  &-rightSection{
    display: flex;
    align-items: center;
    margin-top:20px;


    button {
      height: 35px;
      margin: 0 0 0 10px;
    }
  }

  .reset-btn{
    border-color:$error-color;
    color:$error-color;
  }

}

.hide {
  display: none;
}

@media only screen and (max-width: 1327px) {
  /*.MonthlyTimeLineButtonBar {
    &-leftSection {
      order: 2;
    }

    &-rightSection {
      order: 1;
      margin-bottom:20px;
    }
  }*/
}



@import 'src/style/global';

.AddVehicleServiceModal {
  background: white;
  padding: 17px 44px;
  @include modalWidth();
  border-radius: 8px;
  z-index: 1001;
  max-height: 80%;
  min-height: 200px;

  .TextInput {
    input[type="text"]{
      height: 50px;
    }
  }

  .row {
    display: flex;
  }

  .shiftWarning {
    color: $error-color;
  }

  .w-h-g {
    width: 55% !important;
  }

  .w-h {
    width: 15%;
    margin: 0 2.5%;


    .full {
      width: 100%;
    }
  }

  .margin-top {
    margin: 10px 0;
  }

  .close {
    margin: 50px 2.5% 0 2.5%;
    cursor: pointer;
  }

  .add-btn {
    float: left;
    cursor: pointer;
    margin: 2.5% 0 5% 0;
    color: $primary-color;
  }

  .AddVehicleServiceModal-Body {

    .new-worker-modal {
      width: 80%;
    }
  }

  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  &-Buttons {
    button {
      margin: 50px 5px 20px 5px;
    }

    float: right;
  }
}

.AddVehicleServiceModal-Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}



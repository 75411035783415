@import '../../style/global';

.TimeLine {
  font-family: $font-family-base;

  &-title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  &-content {
    display: flex;
    background: #fff;
    max-height: $max-height-timeline;
  }

  &-table-wrapper {
    display: flex;
    overflow: scroll;
    width: 100%;
  }

  &-table {
    border: 1px solid #ccc;
    min-width: 100%;
    position: relative;
    z-index: 0;
  }

  .above-title {
    width: 100%;
    min-height: 2%;
    float: left;
    color: $primary-color;
    cursor: pointer;

    * {
      float: left;
      margin: 10px 0;
    }
  }
}

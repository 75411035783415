@import '/src/style/global';

.TimeLineNavigation {
  width: 100%;
  height: 53px;
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgba(141, 161, 176, 0.32);
  background-color: #ffffff;

  margin-bottom: -3px;
  position: relative;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  &-goBack {
    position: absolute;
    left: 10px;

    .Button {
      align-items: center;
      display: flex;

      .IconChevronCircleLeft {
        margin-right: 3px;
      }
    }
  }

  .TimeLineNavigation-buttons {
    align-items: center;
    display: inline-flex;

    .IconChevronLeft, .IconChevronRight {
      cursor: pointer;

      &.disabled {
        opacity: .609;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .react-date-picker__wrapper {
    display: none;
  }

  .react-date-picker__inputGroup {
    display: none;
  }

  @include for-tablet-landscape-up{
    .react-calendar {
      position: absolute;
      left: -60%;
    }
  }


  .navigation-label {
    cursor: pointer;

    &:hover {
      color: #09a7ef;
    }
  }
}

@import "../../style/global";

.NeedModal {

  .error {
    color: $error-color;
  }
  .above-title {
    width: 100%;
    min-height: 2%;
    float: left;
    color: $primary-color;
    cursor: pointer;

    * {
      float: left;
      margin: 10px 0;
    }
  }

  .up-title {
    width: 40%;
    margin: -20px 0 0 0;
    float: left;
  }

  .up-controls {
    float: right;

  }

  h2 {
    margin: 2% 0;
  }

  .form-content {
    background: white;
    float: left;
    width: 100%;
    margin: 15px 0 0 0;
    border-radius: 5px;
    padding-bottom: 15px;

    .form:first-child {
      margin: 1% 0 2% 0;
    }

    .form {
      padding: 0 10px;
    }

    @include for-tablet-landscape-up() {
      .form {
        width: 95%;
        padding: 0 5%;
      }
    }

  }

  .top-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .control-full {
      margin: 0 20px;
      width: 100%;
    }

    .control {
      margin: 0 20px;
      flex-grow:1;

      .height select {
        padding-top: 2px;
        padding-bottom: 2px;
        width: 100%;
        margin: 0;
      }

      .react-date-picker {
        width: 100%;
      }
    }
  }

  .add-vehicle-btn, .add-human-shift-btn {
    float: left;
    color: $primary-color;
    cursor: pointer;
  }
}

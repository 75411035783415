@import 'src/style/global';

.MonthlyPlanningModal {
  background: white;
  padding: 17px 44px 44px 44px;
  border-radius: 8px;
  @include center();
  display: none;

  .row {
    width: 100%;
    display: flex;
    min-height: 40px;
    flex-direction: row;
    margin: 5px 0;
  }

  .radio-space {
    width: 50%;
  }

  .selector {
    width: 50%;
  }

  .Button.add-btn {
    float: right;
    width: 138px;
    margin: 20px 0 0 0;
  }

  .Button.cancel-btn {
    float: right;
    width: 138px;
    margin: 20px 15px 0 0;
  }

  .buttons {
    min-height: 50px;
  }

  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  .TextInput {
    input {
      width: 100%;
    }

    &-label {
      margin: 10px 0;
    }
  }

}

.FocusPanel{
  z-index:10;
}

.show {
  display: inline;
}

@import '../../../../style/global';

.DailyVehicleLine{
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;

  &-timeOff{
    color: $error-color;
  }
}

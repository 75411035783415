@import '../../../../style/global';

.MonthlyEmployeeManualSettingsLine,
.MonthlyEmployeeManualSettingsLinetimeOff-f,
.MonthlyEmployeeManualSettingsLinetimeOff-ar,
.MonthlyEmployeeManualSettingsLinetimeOff-gr{
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-color;
  height: 100%;

  &.onEdit{
    cursor: pointer;
  }

  &.timeOff-f{
    color:$error-color;
  }

  &.timeOff-ar{
    color:$font-dark-color2;
  }

  &.timeOff-gr{
    color:#b0da72;
  }
}

.IconSettings {
  cursor: pointer;
}

@import '../../style/global';

.FocusPanel {
  display: none;
  width: 100%;
  background: rgba(0,0,0,0.4);
  position: fixed;
  inset: 0;
  height: 100%;

  .center {
    position: absolute;
    top: 30%;
    left: 40%;
    padding: 2%;
    background: white;
    color: $primary-color;
    border-radius: 5px;
  }
}

.FocusPanel.show {
  display: inline;
}


@import "../../../style/global";

.WorkerRegistryDetails {
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    float: left;
    @include bigger-bold-dark-text();
  }
  .details {
    margin-top: 30px;
    .registrySubtitle {
      .subtitle {
        float: left;
        @include big-medium-dark-text();
      }
    }
    .actionButton {
      float: right;
    }
    .registryDetails {
      width: 100%;
      float: left;
      display: flex;
      flex-direction: column;
    }
    .row {
      margin-top: 20px;
    }
    .lbl {
      width: 33%;
      float: left;
      label {
        @include default-text();
      }
      .value {
        margin-top: 10px;
        @include default-bold-text();
      }
    }
    .second-row {
      .lbl {
        width: 50%;
      }
    }
  }
}

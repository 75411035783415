.container-vehicle-list {

  table {
    border-collapse: collapse;
    position: relative;
  }




  table {
    position: relative;
    height: 1px;
  }

  thead th {
    background-color: #e0e0e0;
    padding: 5px;
  }

  thead {
    background: white;
    z-index: 1;
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }

}

@import '../../../style/global';

.DailyNavigationBar {
  display: flex;
  align-items: center;
  height: 53px;
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgb(141 161 176 / 32%);
  background-color: #ffffff;
  margin-bottom: -3px;
  position: relative;
  z-index: 5;

  &-goBack{
    height: 100%;
    padding: 20px;

    width: 233px;
    border-right: 2px solid #ccc;

    .Button{
      display: flex;
      align-items: center;
      .IconChevronCircleLeft{
        margin-right:5px;
      }
    }
  }

  &-actions{
    //width: 233px;
    width: 266px;
    border-right: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .Button{
      padding: 7px;
      margin-right:5px;
      border: 2px solid $primary-color;
    }
  }
  .TimeLineNavigation{
    flex-grow: 1;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    margin-bottom: 0;
    position: initial;
    justify-content: center;
  }
}

@import 'src/style/global';

.WizardStepOne{
  .dehactivated-users {
    * {
      float:left;
    }
    color: $warning-color;
    span {
      margin: 0 0 0 3px;
    }
  }
  &-actionBar{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .SearchInput{
      min-width: 400px;
    }
    .Button{
      margin: 10px 0;
    }
  }
  .TimeLine-content{
    max-height: $max-height-timeline;
  }
}

@import "../../../style/global";



.SkillsSection {
  display: flex;
  flex-direction: column;

  .certificate-label {
    background: $commonLabelColor;
    border-radius: $label-border-radius;
    margin: 0 0 2px 1px;
    border: solid 1px $commonLabelColor;
    padding: 4px 18px 3px 20px;
    float: left;
  }

  .skill {
    margin: 0 0 2px 1px;
    padding: 4px 18px 3px 20px;
    float: left;
    border-radius: 3px;
  }

  .EM_PLUS {
    background: $em_plus_background;
    color: $em_plus;
    border: solid 1px $em_plus;
  }

  .EM {
    background: $em_background;
    color: $em;
    border: solid 1px $em;
  }

  .INTERH {
    background: $interh_background;
    color: $interh;
    border: solid 1px $interh;
  }

  .INTRAH {
    background: $intrah;
    color: $font-dark-color2;
    border: solid 1px $font-dark-color2;
  }

  .MOSS {
    background: $moss;
    color: $font-dark-color2;
    border: solid 1px $error-color;
    color: $error-color;
  }

  .row {
    display: flex;
  }

  .column-50 {
    width: 50%;
  }

  .details {
    .registrySubtitle {
      .subtitle {
        float: left;
        @include big-medium-dark-text();
      }
    }

    .registryDetails {
      width: 100%;
      float: left;
      display: flex;
      flex-direction: column;
    }

    .row {
      margin-top: 20px;
    }

    .lbl {
      float: left;

      .value {
        margin-top: 10px;
        @include default-bold-text();
      }
    }
  }
}

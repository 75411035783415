@import '/src/style/global';

.MonthlyPlanningTitleBar {
  margin: 1rem;
  font-size: 1.5rem;

  @include for-tablet-portrait-up {
    margin: 0;
  }
}

@import "../../../style/global";

.WorkerRegistryContracts {
  display: flex;
  flex-direction: column;
  .contractSubtitle {
    .subtitle {
      float: left;
      @include big-medium-dark-text();
    }
  }
  .actionButton {
    float: right;
  }
  .contractDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    .row {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .lbl {
        width: 33%;
        float: left;
        flex-grow: 1;
        label {
          @include default-text();
        }
        .value {
          margin-top: 10px;
          @include default-bold-text();
        }
      }
    }
  }
}

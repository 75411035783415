@import '/src/style/global';

.TimeLineYAxis {
  border: 1px solid #ccc;
  position: sticky;
  left: 0;
  background: #fff;
  z-index: 4;

  &-buffer-cell{
    width: 170px;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
  }

  &-cell {
    width: 170px;
    border-bottom: 1px solid #ccc;
  }

  @include for-tablet-portrait-up {
    &-buffer-cell {
      width: 230px;
    }
    &-cell {
      width: 230px;
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
/* Body */
*, *:before, *:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html, body {
  font-family: Roboto, Verdana, Geneva, sans-serif;
  color: #4b5760;
  background-color: #f3f6f8;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 18px;
}

.TextInput-borderError {
  border: solid 2px #df5858;
  border-radius: 5px;
}

.above-title {
  width: 100%;
  min-height: 10px;
}

.Dashboard .up-title {
  width: 40%;
  float: left;
}

.modal {
  z-index: 100;
}

.inner-content {
  margin: 0;
}

@media (min-width: 600px) {
  .inner-content {
    margin: 0 10px;
  }
}
@media (min-width: 900px) {
  .inner-content {
    margin: 0 20px;
  }
}
@media (min-width: 1200px) {
  .inner-content {
    margin: 0 30px;
  }
}
@media (min-width: 1800px) {
  .inner-content {
    margin: 0 80px;
  }
}
.skillLabel {
  margin: 0 0 2px 1px;
  padding: 1px 5px;
  float: left;
  border-radius: 3px;
  font-family: Roboto, serif;
  color: #212b36;
  fill: #212b36;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.actionable {
  cursor: pointer;
}

.EM_PLUS {
  background: #fff2e8;
  color: #ffca8c;
  border: solid 1px #ffca8c;
}

.EM {
  background: #ebf6da;
  color: #b0da72;
  border: solid 1px #b0da72;
}

.INTERH {
  background: #c6ecfd;
  color: #09a7ef;
  border: solid 1px #09a7ef;
}

.INTRAH {
  background: #e8f3ff;
  color: #424d54;
  border: solid 1px #424d54;
}

.MOSS {
  background: rgba(223, 88, 88, 0.2);
  color: #424d54;
  border: solid 1px #df5858;
  color: #df5858;
}
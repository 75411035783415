@import '../../style/global';


$label-border-radius: 4px;

.search-result {
  margin: 20px 0;
}

.search-location {
  margin: 20px 0;
}

.up-controls {
  .right-btn {
    float: right;
  }
}

.Dashboard h1 {
  float: left;
}

.Dashboard .up-controls {
  width: 60%;
  float: left;
  margin-top: 15px;

  button {
    float: right;
    margin: 0 0 0 10px;
  }
}

.Dashboard .up-title {
  width: 40%;
  float: left;
}

.SideBarContent {
  display: flex;
  flex-direction: column;

  .WorkerRegistryDetails {
    margin-top: 40px;
  }

  .LineHorizontal {
    margin: 25px 0;
  }
}

.headerCompleteness {

  position: relative;

  .title {
    float: left;
  }

  .TooltipIcon {
    float: left;
  }

  .wrap {
    display: none;
    position: absolute;
    z-index: 1000;
    background: #fff2e8;
    border-radius: 4px;
    margin: 0 0 2px 1px;
    border: solid 1px #ffca8c;
    padding: 2px 10px;
    color: #ffca8c;
    width: 150px;
    top: 25px;
    left: 0;
  }
}

.headerCompleteness:hover {
  .wrap {
    display: inline;
  }
}

.Table {
  .not-active {
    float: left;
    $errorColor: #ffca8c;
    background: #fff2e8;
    border-radius: $label-border-radius;
    margin: 0 0 2px 1px;
    border: solid 1px $errorColor;
    padding: 2px 10px;
    color: $errorColor;
  }

  .active {
    float: left;
    $okColor: #b0da72;
    background: #ebf6da;
    border-radius: $label-border-radius;
    margin: 0 0 2px 1px;
    border: solid 1px $okColor;
    padding: 2px 10px;
    color: $okColor;
  }
}

.WorkersTableContainer{
  .Table{
    tr{
      cursor: pointer;
    }
  }
}

@import "../../../style/global";

$label-border-radius: 4px;

.circuit {

}

.row {
  width: 100%;
  float:left;
}

.decorated {
  background: $background-soft-color;
  border-radius: $label-border-radius;
  margin: 0 0 2px 1px;
  padding: 4px 18px 3px 20px;
  float: left;
}

.circuit-title {
  font-weight: bolder;
  color: $font-dark-color;
}

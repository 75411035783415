@import 'src/style/global';

.KBMenuModal {
  width: 150px;
  background: white;
  min-width: 50px;
  border-radius: 8px;
  z-index: 1001;
  min-height: 30px;
  box-shadow: 0 0 4px $font-placeholder-color;


  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  &-Buttons {
    text-align: center;
    padding: 10px 0;
    :hover {
      cursor: pointer;
      background-color: $primary-color;
      color: white;
    }
  }
}

.KBMenuModal-Overlay {
  position: absolute;
  z-index: 1000;
  align-items: center;
  justify-content: center;
}

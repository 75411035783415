@import '/src/style/global';

.ViewSelector {

  float: left;

  .ViewSelector-actions {
    button {
      margin: 0;
      border: 2px solid $primary-color;
      border-radius: 0;
      min-height: 40px;
      padding: 16px;
    }

    button:nth-child(1) {
      border-radius: 5px 0 0 5px;
    }

    button:nth-child(2) {
      border-left: 0;
      border-right: 0;
    }

    button:nth-child(3) {
      border-radius: 0 5px 5px 0;
    }
  }
}

@import '../../style/global';

.TooltipWrapper {
  position: relative;
  display: inline-block;

  .TooltipContainer {
    width: $tooltip-width;
    position: absolute;
    display: inline-block;
    background-color: $tooltip-color;
    border-radius: $tooltip-border-radius;
    padding: $vertical-standard-padding $horizontal-standard-padding;
    z-index: 1;
    left: 50%;
    @include small-text();

    .Title {
      @include default-bold-text();
    }

    .cell {
      line-height: 22px;
    }

    &::after {
      opacity: 1;
      content: "";
      position: absolute;
      border-style: solid;
      left: 50%;
      height: 30%;
    }

    &.Top {
      transform: translate(-50%, calc(-100% - calc(#{$tooltip-arrow-width} / 2)));

      &::after {
        border-width: $tooltip-arrow-height calc(#{$tooltip-arrow-width} / 2) 0 calc(#{$tooltip-arrow-width} / 2);
        border-color: $tooltip-color transparent;
        top: 100%;
        transform: translate(-50%, -1px);
      }
    }

    &.Bottom {
      top: 100%;
      transform: translate(-50%, calc(#{$tooltip-arrow-width} / 2));

      &::after {
        border-width: 0 calc(#{$tooltip-arrow-width} / 2) $tooltip-arrow-height calc(#{$tooltip-arrow-width} / 2);
        border-color: $tooltip-color transparent;
        bottom: 100%;
        transform: translate(-50%, +1px);
      }
    }
  }

  &.ErrorTooltip .TooltipContainer {
    background-color: $error-color;
    color: $text-color-on-error-color-background;

    &::after {
      border-color: $error-color transparent;
    }
  }

  &.ShowOnHover:not(:hover) .TooltipContainer {
    visibility: hidden;
  }
}

/*.cell{
  line-height: 22px;
}

span {
  display: inline-block;
  width: 70%;
}
b {
  display: inline-block;
  width: 30%;
  text-align: right;
}*/

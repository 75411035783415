@import 'src/style/global';



.MonthlyPlanningModal {

  .row {
    width: 100%;
    display: flex;
    min-height: 40px;
    flex-direction: row;
    margin: 5px 0;
  }

  .radio-space {
    width: 50%;
  }

  .selector {
    width: 30%;
    float: left;

    &-first {
      width: 100%;
      float: left;
    }

    .TextInput {
      input[type="text"] {
        max-width: 120px;
      }
    }

    .TextInput-label {
      float: none;
    }
  }

  .selector-time {
    width: 60%;
    margin: 0 0 0 5%;

    .dash {
      float:left;
      margin: 50px 10px 0 10px;
    }

    .closeIcon {
      float:left;
      margin: 40px 10px 0 10px;
      font-size: 20px;
    }

    .TextInput {
      float:left;
      width: 30%;
    }
  }

  .add-btn-wrap-hide {
    display: none;
  }

  .hide {
    display: none;
  }

  .add-btn {
    color: #09a7ef;
    cursor: pointer;
    margin: 20px 0 0 0;
  }

  .Button.add-btn {
    float: right;
    width: 138px;
    margin: 20px 0 0 0;
    color:white;
  }

  .Button.cancel-btn {
    float: right;
    width: 138px;
    margin: 20px 15px 0 0;
  }

  .buttons {
    min-height: 50px;
  }

  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  .TextInput {
    input {
      width: 100%;
    }

    &-label {
      margin: 10px 0;
    }
  }
}

.show {
  display: inline;
}

@include for-tablet-portrait-up {
  .Modal{
      width: 600px;
  }
}

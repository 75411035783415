.DatePicker {
  display: flex;
  flex-direction: column;
  label{
    width: 100%;
  }

  .value-input {
    position: relative;
    .react-date-picker__wrapper {
      width: 100%;
      float: left;
      height: 40px;
      margin: 0;
      border: none;
      outline: none;
      padding: 0 16px;
      border-radius: 6px;
      background-color: #f3f6f8;
    }
    .icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 10px;
    }
  }
}

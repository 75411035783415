@import '/src/style/global';

.MonthlyPlanning {

  padding: 20px 0 0 0;

  @include for-tablet-landscape-up {
    padding: 20px;
  }

  @include for-desktop-up {
    padding: 40px;
  }

  .row {
    width: 100%;
    float: left;
    margin: 10px 0;
  }

  .outer-wizard {
    float:left;
    width: 100%;
  }

  .search-input-outer {
    width: 100%;
    float: left;
    border-bottom: 1px solid $color-pale-grey;
  }
}



@import '../../style/global';

.LineHorizontal {
  width: 90%;
  background-color: #dae4f0;
  height: 2px;
  margin: auto;

  &.Full {
    width: 100%;
    background-color: #dae4f0;
    height: 1px;
  }
}

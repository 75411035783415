.ShiftModal {

  /*max-width: 40%;*/

  .form {

    .Select select{
      padding: 10px 16px;
    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .row-2-1 {
      .row-w{
        width: 45%;
      }
    }

    .row-1 {

      .row-w {
        width: 47%;
      }

      .row-w:nth-child(2) {
        margin-left: 20px;
      }

      .height {
        padding: 2px;
      }
    }

    .row-2 {
      width: 100%;

      .row-w {
        width: 20%;
      }

      .divider {
        width: 5%;
        margin-top: 45px;
        margin-left: 10px;
      }

      .column-2 {
        margin-left: 20px;
        width: 45%;
      }
    }

    .row-3 {
      width: 100%;
      display: flex;
      flex-direction: row;

      .row-w {
        width: 47%;
        margin:0 15px 0 0;
      }
    }

    input[type="checkbox"] {

      margin: 25px 10px;
    }
  }
}

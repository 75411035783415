@import 'src/style/global';

$label-font-size: 14px;

.HumanEntry {
  margin: 0 auto;
  width: 100%;
  border: 1px solid $color-light-blue;
  padding: 2%;
  border-radius: 3px;
  position: relative;

  .first-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .moss {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      label {
        font-size: $label-font-size;
        /*float: left;*/
        margin-top: 10%;
        width: 100%;
      }

      .radio-moss-container{
        display: flex;
      }

      .radio-moss {
        width: 40%;
        margin: 15% 5%;
        display: flex;
      }

      .radio-moss label {
        /*float:left;*/
        width: 45%;
        margin: -2% 0%;
      }
      .radio-moss input[type=radio] {
        /*float:left;*/
      }

    }

    .row-w {
      min-width:284px;
      margin-right: 20px;

      select {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }

  .second-row {
    /*float: left;*/
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    div {
      min-width: 284px;
    }

    .timespan {
      padding: 10px 2% 0 2%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        padding: 27px 0;
        width: 100%;
      }

      label {
        font-size: $label-font-size;
      }
    }
  }

  .fourth-row {
    margin: 2% 0;

    Table {
      width: calc(100% - 2px);

      thead {
        background: $color-light-blue;

        th:first-child {
          border-radius: 5px 0 0 0;
        }

        th:last-child {
          border-radius: 0 5px 0 0;
        }

        th {
          text-align: center;
        }

      }

      tr {
        margin: 2% 0;

        input {
          margin: 10% 0;
          min-width:40px;
          padding: 8px;
        }

        td {
          border-radius: 5px;
          border: 1px solid #bedbf5;
          padding: 0 5px;
        }

        @include for-tablet-landscape-up(){
          td {
            padding: 0 20px;
            input{
              padding: 16px;
            }
          }
        }

      }

      .TextInput {
        margin-top: 5%;
        /*float: left;*/
      }
    }
  }

  .fifth-row {
    margin: 2% 0;

    Table {
      width: calc(100% - 2px);
      thead {
        background: $error-bg-color;

        th:first-child {
          border-radius: 5px 0 0 0;
        }

        th:last-child {
          border-radius: 0 5px 0 0;
        }

        th {
          text-align: center;
        }

      }

      tr {
        margin: 2% 0;

        input {
          margin: 10% 0;
          min-width:40px;
          padding: 8px;
        }

        td {
          border-radius: 5px;
          border: 1px solid $error-bg-color;
          padding: 0 5px;
        }

        @include for-tablet-landscape-up(){
          td {
            padding: 0 20px;
            input{
              padding: 16px;
            }
          }
        }

      }

      .TextInput {
        margin-top: 5%;
        /*float: left;*/
      }
    }
  }

  .table-wrapper{
    overflow: auto;
  }

  .row {
    margin: 2% 0 0 0;
    color: $text-color;
  }

  .closeIcon {
    position: absolute;
    font-size: 26px;
    top: 2%;
    right: 2%;
    cursor: pointer;
  }
}

@import '../../style/global';

.TopMenu {
  display: flex;
  background: #fff;
  /*height: 50px;*/

  .hidden {
    display: none;
  }

  .toggle-menu {

    position: relative;

    .submenu {
      padding: 9px 34px 4px 17px;
      box-shadow: 0 2px 4px 0 rgba(141, 161, 176, 0.38);
      @include for-tablet-landscape-up {
        position: absolute;
        min-width: 215px;
        top: 34px;
        left: -20px;

        border-radius: 8px;
        background: white;

        z-index: 10;
      }

      ul {
        padding-left: 0;

        li {
          margin: 10px 0;

          &.active {
            font-weight: bolder;
            border-bottom: 0;
            padding-bottom: 0;
          }
        }

      }

      .hidden {
        display: none;
      }
    }
  }

  ul li {
    cursor: pointer;
    list-style: none;
    flex-direction: row;
    margin: 0 10px;
    height: 100%;
    font-size: $font-size;
    align-items: center;
    display: flex;

    &.logout{
      flex-grow: 1;
      justify-content: flex-end;
      min-width: 52px;
    }

    a {
      display: flex;
    }

    &.active {
      border-bottom: 2px solid #09a7ef;
    }
  }
  .blue-btn {
    height: 60px;
  }
  @include for-tablet-landscape-up {
    .blue-btn {
      width: 50px;
      height: 50px;
      background: $primary-color;
    }
  }


  @include for-desktop-up {
    ul li {
      margin: 0 20px;
    }
  }

  .Icon-close {
    margin: 2px;
    opacity: 0.8;
  }

  &-StatusIndicator {
    cursor: pointer;
    align-self: center;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;
    background-color: rgba(9, 167, 239, 0.15);
    border-radius: 4px;
    display: flex;
    align-items: flex-end;

    * {
      float: left;
    }

    .submitted {
      opacity: 0.2;
    }

    .TopMenu-StatusLabel {
      margin: 2px 2px 2px 5px;

      * {
        float: left;
      }

      .icon-loading {
        margin: 2px 0 0 0;
      }
    }

    .IconLoading {
      margin-left: 10px;
    }
  }

}

.TopMenu > ul {
  display: flex; /* or inline-flex */
  margin: 0;
  flex-grow: 1;
  padding: 0;
  overflow-x: auto;
  justify-content: space-between;
  align-items: center;

}

@include for-tablet-landscape-up {
  .TopMenu > ul {
    padding-left: 40px;
    overflow-x: initial;
    height: auto;
  }

  ul li {
    margin: 0 15px;
  }
}

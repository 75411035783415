@import "../../style/global";

.TotemManagers {

  .contents {
    margin-top: 10px;
    float: left;
    width: 100%;
  }

  .left-side {
    width: 50%;
    float: left;
  }

  .right-side {
    width: 50%;
    float: left;
  }

  .sectioCns-btn {
    button {
      margin: 0;
      border: 2px solid $primary-color;
      border-radius: 0;
      min-height: 40px;
      padding: 16px;
      background-color: white;
    }

    button:nth-child(1) {
      border-radius: 5px 0 0 5px;
    }

    button:nth-child(2) {
      border-left: 0;
      border-right: 0;
    }

    button:nth-child(3) {
      border-radius: 0 5px 5px 0;
    }
  }

  .table {
    margin-top: 5px;
    margin-bottom: 25px;
    float:left;
    width: 100%;
  }

  .circuit-select select {
    background-color: white !important;
  }
}

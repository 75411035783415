@import '../../style/global';

.Login {
  h1 {
    text-align: center;
    color: $primary-color;
    font-size: 3.25rem;
    margin-bottom: 1rem;
  }
}

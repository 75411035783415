.side-bar {
  position: fixed;
  right: calc(100vw - 100vw); /* Calcola la larghezza della scrollbar */
  top:0;
  background-color: #e0e0e0;
  min-width: 30px;

  display: flex;
  flex-direction: row;
  z-index: 6;
  background-color: transparent
}



.tabs {
  cursor: pointer;
  width: 30px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #303030;
  font-size: 14px;
  font-weight: bold;
  //rotate text by 90 degrees

  .tab {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px;
    padding-top: 15px;
    padding-bottom: 15px;

    border-bottom: 1px solid #303030;
    .label {
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
      transform: rotate(180deg);
    }

    .count {
      margin-top: 15px;
      font-size: 12px;
      padding: 0px;
      font-weight: bold;

      border-radius: 12px;
      background-color: #303030;
      color: white;
      width: 24px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .tab.warningtab {

    .count {
      background-color: #AA0000;
      color: white;
    }
  }


  .tab.selected {
    background-color: #303030;
    color: white;

    .count {
      background-color: white;
      color: #303030;
    }
  }

  .tab.selected.warningtab {

    background-color: #AA0000;
    color: white;

    .count {
      background-color: white;
      color: #AA0000;
    }
  }

  .tab.dropHighlight {
    border: 2px solid orange;
    padding-left: 1px;
    padding-right: 1px;


    padding-top: 13px;
    padding-bottom: 14px;
  }
}

.selected-tab {
  background-color: white;
  cursor: pointer;
  border: 1px solid #303030;
  min-width: 250px;



  .tabHeader {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    background-color: #303030;
    color: white;

    .label {
      flex-grow: 1;

      text-align: center;
    }
  }
}


.selected-tab.visible {
  max-height: 100vh;
  overflow-y: scroll;
}

.workersSlot {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: white;
}

.trash
{
  width: 12px;
}


.tab.elimina {
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: darkgray;
  color: 303030;

  .count {
    background-color: silver;
    color: #303030;
  }
}

.tab.elimina.dropHighlight {
  background-color: #303030;
  color: white;

  .count {
    background-color: white !important;
    color: #303030;
  }

  padding-top: 28px;
  padding-bottom: 18px;
}


.reduced .tab {
  padding-top: 15px;
  padding-bottom: 10px;
}

.reduced .tab .count {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.reduced  .tab.elimina.dropHighlight {
  padding-top: 13px;
  padding-bottom: 8px;
}

.reduced .tab.dropHighlight {
  padding-top: 13px;
  padding-bottom: 8px;
}

@import "../../style/global";

.TotemWorkers {
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .date,  .selectedDate  {
    text-align: center;
    padding: 15px 30px;
    margin: 10px;
    background-color: #D7D9D7;
    cursor: pointer;
  }

  .date:hover {
    background-color: #A7A9A7;
    color: white;
    text-decoration: underline;
  }

  .selectedDate {
    color: white;
    background-color: #09A7ef;
    text-decoration: underline;
  }

  .table {
    float: left;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 30px;
  }

  .CircuitSelect Select {
    margin-top: 10px;
    background-color: white;
    height: 40px;
    padding-top:10px;
  }

  .controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    * {
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
    }

    *:nth-child(2) {
      padding-left: 5%;
      order: 0;
      flex: 1 2 auto;
      align-self: auto;
    }
  }
}

@media only screen and (max-width: 1024px) {

  .date {
    text-align: left !important;
  }

  .search-bar input {
    width: 100% !important;
  }

  .TotemWorkers .inner-content {
    width: 50%;
    margin: 10px !important;
  }

  .TotemWorkers .table .Table th,
  .TotemWorkers .table .Table tr td {
    font-size: 16px;
    padding: 0 5px !important;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px !important;
  }

  .TotemWorkers .table .Table th {
    padding: 0 10px;
  }

  .TotemWorkers .table .Table tr td:nth-child(1) {
    min-width: 100px !important;
    overflow: hidden;
    white-space: pre-wrap;
  }

  .TotemWorkers .table .Table th:nth-child(2),
  .TotemWorkers .table .Table tr td:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .TotemWorkers .table .Table th:nth-child(4),
  .TotemWorkers .table .Table tr td:nth-child(4) {
  }

  .TotemWorkers .table .Table th:nth-child(5),
  .TotemWorkers .table .Table tr td:nth-child(5) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .TotemWorkers .table .Table tr:hover td {
    max-width: 50px;
  }



}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;

  align-items: flex-start;
  font-weight: bold;
  width: 100%;

  .title {
    text-align: left;
    font-size: 20px;
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.footer > * {
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 40px;
}

.hidden {
  display: none;
}

.adminbar {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
}

.footer {
  table {
    background-color: #ffffff;
    border-collapse: collapse;
    min-width: 500px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    border:2px solid #dae4f0;
  }
  thead{
    tr{
      height: 40px;
      border-bottom:1px solid #dae4f0;
      th{
        font-size: 14px;
        color: #8da1b0;
        text-transform: uppercase;
        font-weight: normal;
        padding:0 20px;
        flex-direction: row;
        text-align: left;
      }
    }
  }

  tbody{
    tr{
      height: 40px;
      background-color: #ffffff;
      border-bottom:1px solid #dae4f0;
      &:last-child{
        border-bottom:none;
      }
      td{
        padding:0 20px;
        font-size: 14px;
        color: #424d54;
        flex-direction: row;
        vertical-align: text-top;
      }
      &:hover{
        background-color: #f8fbff;
      }
    }
  }

  .operators {
    padding: 10px;
  }

  .operators_head {
  }

  .turn_head {
    width: 200px;
  }
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

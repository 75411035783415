@import 'src/style/global';

.VehicleEntry {
  margin: 0 auto;
  width: 100%;
  border: 1px solid $color-light-blue;
  padding: 2%;
  border-radius: 3px;
  position: relative;

  .first-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .dash {
      padding: 4% 0 0 0;
      width: 3%;
    }

    .row-w {
      min-width:284px;
      margin-right: 20px;

      select {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }

  .second-row {
    margin: 2% 0;

    Table {
      width: calc(100% - 2px);
      thead {
        background: $color-light-blue;

        th:first-child {
          border-radius: 5px 0 0 0;
        }

        th:last-child {
          border-radius: 0 5px 0 0;
        }

        th {
          text-align: center;
        }

      }

      tr {
        margin: 2% 0;

        input {
          margin: 10% 0;
          min-width:40px;
          padding: 8px;
        }

        td {
          border-radius: 5px;
          border: 1px solid #bedbf5;
          padding: 0 5px;
        }

        @include for-tablet-landscape-up(){
          td {
            padding: 0 20px;
            input{
              padding: 16px;
            }
          }
        }

      }

      .TextInput {
        margin-top: 5%;
        /*float: left;*/
      }
    }
  }

  .third-row {
    margin: 2% 0;

    Table {
      width: calc(100% - 2px);
      thead {
        background: $error-bg-color;

        th:first-child {
          border-radius: 5px 0 0 0;
        }

        th:last-child {
          border-radius: 0 5px 0 0;
        }

        th {
          text-align: center;
        }

      }

      tr {
        margin: 2% 0;

        input {
          margin: 10% 0;
          min-width:40px;
          padding: 8px;
        }

        td {
          border-radius: 5px;
          border: 1px solid $error-bg-color;
          padding: 0 5px;
        }

        @include for-tablet-landscape-up(){
          td {
            padding: 0 20px;
            input{
              padding: 16px;
            }
          }
        }

      }

      .TextInput {
        margin-top: 5%;
        /*float: left;*/
      }
    }
  }

  .table-wrapper{
    overflow: auto;
  }

  .row {
    margin: 2% 0 0 0;
    color: $text-color;
  }

  .closeIcon {
    position: absolute;
    font-size: 26px;
    top: 2%;
    right: 2%;
    cursor: pointer;
  }
}

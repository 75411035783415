@import '../../../../../style/global';

.MonthlyPlanningManualSettingsHeader{
  text-align: center;

  &-container{
  }

  &-f, &-ar, &-gr{
    text-align: center;
    padding: 2px 10px;
    margin: 5px;
    font-weight:bold;
    border-radius:5px;
    font-size: 14px;
  }

  &-f{
    background:#ffd1d1;
    color:$error-color;
    border:1px solid $error-color;
  }

  &-ar{
    background: $color-light-blue;
    color: $font-dark-color2;
    border: 1px solid $font-dark-color2;
  }

  &-gr{
    background: #ebf6da;
    color: #b0da72;
    border: 1px solid #b0da72;
  }

  &-subtext{
    font-size: 12px;
    text-align: center;
    color: #8da1b0;
  }
}

@import "../../style/global";

.search-result {
  margin: 20px 0;
}

.search-location {
  margin: 20px 0;
}

.Dashboard h1 {
  float: left;
}

.Dashboard .up-controls {
  width: 60%;
  float:left;
  margin-top: 15px;

  button {
    float:right;
    margin: 0 0 0 10px;
  }
}

.Dashboard .up-title {
  width: 40%;
  float:left;
}

.TypologyContainer {
  display: flex;
  flex-wrap: wrap;
  width: 175px;
  justify-content: space-between;
  .Typology {
    @include default-bold-text();
    text-transform: uppercase;
    border-radius: 8px;
    background-color: $typology-color;
    padding: 5px 10px;
  }
}

.DescriptionContainer {
  width: 180px;
}

.slide-down[data-reach-menu-list],
.slide-down[data-reach-menu-items] {
  border-radius: 10px;
  width: 130px;
}

.itemMenu {
  @include default-text();
}

[data-reach-menu-button] {
  background-color: transparent;
  border-color: transparent;
}

[data-reach-menu-item][data-selected] {
  background: $primary-color !important;
}

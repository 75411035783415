@import '/src/style/global';

.DailyPlanning{

  .search-input {
    input {
      border: 1px solid $background-color;
    }
  }

  .add-user {
    position: absolute;
    top: 15px;
    left: 45px;
    z-index: 100;
  }

  @include for-tablet-portrait-up {
    padding:40px;
  }

  .DailyServicesTimeLine{
    position: relative;
  }

  .DailyPlanningTitleBar{
    margin: 2rem 1rem 2rem 1rem;
    font-size: 1.5rem;
    display: flex;

    .CircuitSelect {
      margin: 0 0 0 10px;
      select {
        margin-top: -6px;
        background-color: white;
      }
    }


    @include for-tablet-portrait-up {
      margin: 0 0 1.5rem 0;

    }
  }

  .TimeLine-content{
    max-height: calc(100vh - 300px);
  }

  .hide { display: none; }

  .actionable { cursor: pointer; }
}



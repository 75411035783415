@import "../../../style/global";

.WorkerUnavailabiltySection {
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    float: left;
    @include bigger-bold-dark-text();
  }
  .weekday-entry {
    width: 30%;
  }
  .details {
    .registrySubtitle {
      .subtitle {
        float: left;
        @include big-medium-dark-text();
      }
    }
    .actionButton {
      float: right;
    }
    .registryDetails {
      width: 100%;
      float: left;
      display: flex;
      flex-direction: column;
    }
    .row {
      margin-top: 10px;
    }
    .lbl .IconCalendar, .lbl .IconClock  {
      width: 5%;
      margin-right: 10px;
    }
    .lbl {
      width: 100%;
      float: left;
      display: flex;
      label {
        @include default-text();
      }
      .value {
        margin-top: 10px;
        @include default-bold-text();
      }
    }
    .lbl .week-days {
      width: 30%;        /* distributes space on the line equally among items */
    }

    .lbl .time:first-child {
      margin: 0 0 0 0;
    }

    .lbl .time {
      margin: 0 0 0 0;
    }
  }
}

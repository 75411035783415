@import '../../../style/global';

.TimeLineXAxis {
  border-bottom: 1px solid #ccc;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: sticky;
  background: #fff;
  top: 0;
  z-index: 4;

  &-cell {
    height: 100%;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

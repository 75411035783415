@import 'src/style/global';

.WorkerServiceTurnModal {
  background: white;
  padding: 17px 44px;
  @include modalWidth();
  border-radius: 8px;
  z-index: 1001;
  max-height: 80%;
  min-height: 200px;

  .row {
    display: flex;
  }

  .shiftWarning {
    color: $error-color;
  }

  .w-s {
    width: 40%;
    margin: 0 1.5%;

    select {
      padding: 8px 16px;
    }
  }

  .w-h {
    width: 30%;
    margin: 0 1.5%;
  }

  .container {
    width: 70% !important;
  }

  .margin-top {
    margin: 10px 0;
  }

  .close {
    margin: 10px 2.5% 0 2.5%;
    cursor: pointer;
  }

  .new-worker-form {
    margin-bottom: 30px;
  }

  .add-btn {
    float: left;
    cursor: pointer;
    margin: 2.5% 0 5% 0;
    color: $primary-color;
  }

  .WorkerServiceTurnModal-Body {
    max-height: 950px;
    //overflow-y: scroll;

    .new-worker-modal {
      width: 80%;
    }
  }

  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  &-Buttons {
    button {
      margin: 10px 5px 20px 5px;
    }

    float: right;
  }
}

.WorkerServiceTurnModal-Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}



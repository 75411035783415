@import '../../style/global';

$length: 540px;

.Toast {
  border-radius: 8px;
  width: $length;
  height: 50px;
  position: fixed;
  z-index: 1;
  padding: 13px 29px 13px 21px;
  left: 50%;
  margin-left: -270px;
}

.Toast {
  margin-top: 10px;
}

.Toast .inner {
  display: flex;
  width: 100%;
}

.Toast p {
  margin: 0;
  font-size: $font-size;
}

.Toast.error {
  background: $error-bg-color;
}

.Toast.success {
  background: $success-bg-color;
}

.Toast.success .success-text {
  color: $success-color;
}

.Toast.error p {
  color: $error-color;
}

.Toast.invisible {
  display: none;
}

.Toast .count-error {
  font-weight: bold;
}


.Toast .inner div {
  flex-direction: row;
}

.Toast .inner div:first-child {
  width: 20%;
}

.Toast .inner div:nth-child(2) {
  width: 100%;
}

.Toast .inner div:last-child {
  width: 40%;
  text-align: right;
}



@import '../../../style/global';

.UnavailableModal {
  width: 595px;

  form {
    width: 100%;
    display: flex;
    flex-direction: row;


    label { font-size: 14px; }
  }

  .close {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    cursor: pointer;
  }

  .row-btn {
    float: left;
    margin: 9px 0 0 6px;
  }

  .side {
    display: flex;
    flex-direction: row;
  }

  .column {
    width: 43%;
  }

  .weekdays { margin-top: 50px }
  .head { display: flex; margin-bottom: 11px; }
  .head label:first-child { width: 50% }
  .head label:nth-child(2) { width: 35% }
  .head label:nth-child(3) { width: 28% }

  .weekend {
    margin-top: 32px;
  }

  .buttons {
    margin: 0 10px 0 0;
  }
}

.WeekToggleGroup {
  width: 100%;
  display: flex;
}

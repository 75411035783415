.card {
  border: 1px solid #303030;
  border-radius: 5px;
  margin: 2px;
  font-size: 14px;
  padding: 0px;
  background-color: white;
  cursor: pointer;

  /*touch-action: none;*/
}


.topLine, .bottomLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px;
}

.topLine {
  margin-bottom: 0px;
  font-size: 9px;
  font-weight: normal;
  background-color: gray;
  color: #e0e0e0;
  border-bottom: 1px solid #303030;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

/*
#F091EE  =INTRAH (Rosa)
#39F=INTERH (Azzurro)
#666=COA5 (Grigio)
#b0da7=EM (Verde)
ffca8c =EM+ (Giallo)
*/

.em {
  .topLine{
    background-color: #b0da70;
    color: #000;
    border-color: #90aa60;
  }
}
.emplus {
  .topLine {
    background-color: #ffca8c;
    color: #000;
    border-color: #ffca8c;
  }
}

.intrah {
  .topLine{
    background-color: #F091EE;
    color: #000;
    border-color: #D071CE;
  }
}

.interh {
  .topLine {
    background-color: #39F;
    border-color: #17D;

  }
}

.moss {
  .topLine {
    background-color: #666;
    border-color: #444;
  }
}


.bottomLine
{
  margin-top: 0px;
  font-size: 12px;
  font-weight: bold;
  color: gray;
  padding: 0px;

  div {
    padding: 3px 5px;
  }
}


.bottomLine {
  flex-shrink: 1;
  flex-grow: 0;
}

.workerName {
  font-weight: bold;
  color: #303030;
  flex-grow: 1;
  flex-shrink: 1;
  max-lines: 2;
  border-left: 1px solid #303030;
}

.workerHeight {

  border-left: 1px solid #303030;
}

.role_nurse {
 .workerName {
    color: #C03030;
  }
}

.role_driver.circuit_ismobo {
  .workerName {
    color: #3030C0;
  }
}

.workerAbilitazione {
  font-weight: bold;
  color: #303030;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 3px;
  padding: 3px;
  border-left: 1px solid #303030;
}

.turn {
  background-color: gray;
  color: white;
}



.overrideTurnTime {
  border: 2px solid orange !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

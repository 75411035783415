@import '../../style/global';

.LoginForm {
  background: white;
  margin: 0 auto;
  padding: 39px 56px 42px 57px;
  border-radius: 8px;
  @include modalWidth();
  max-width: 492px;

  h1 {
    color: #4b5760;
    font-size: 26px;
    text-align: left;
  }

  h2 {
    font-weight: bold;
    font-size: 20px;
  }

  .forgotten-link {
    font-size: 12px;
    font-weight: 500;
    color: $primary-color;
  }
}

.TextInput {
  input {
    width: 100%;
  }

  &-label {
    margin: 10px 0;
  }
}

.Button.login-btn {
  width: 232px;
  margin: 53px 76px 0 75px;
}

.dropSlot {
  height: 25px;
  background-color: gray;
}

.vehicle-time {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.container-vehicle-service {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;

  .container-vehicle-service-top {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px;

    .category {

      border: 1px solid gray;
      border-radius: 5px;
      background-color: #e0e0e0;
      padding: 5px;
      font-size: 10px;
      font-weight: bold;
    }



    .radiocode {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      font-weight: bold;
      padding: 3px;
      flex-grow: 1;
    }
  }

  .container-vehicle-service-bottom {
    flex-grow: 1;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .circuit  {
    display: flex;

    span{
      margin-right: 5px;
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
      background-color: white;
      font-size: 10px;
      font-weight: bold;
    }
  }
}

.container-vehicle-row {
  display: flex;
  align-items: start;
  flex-direction: column;
  align-items: flex-start;
  background-color: aqua;
  margin: 10px;
}


.vehicleRow.active {
  background-color: #e3e3e3;
  border-color: gray;
  color: darkslategray;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.vehicleRow.inactive {
  background-color: #e0e0e0;
  border-color: gray;
  color: gray;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.vehicleRow {
  border-left: 1px solid gray;
  height: 20px;
}

.container-vehicle-row-top {
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}

.container-vehicle-row-bottom {
  height: 1px;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}

.container-vehicle-cell {
  text-align: center;
  padding: 0px;
  border-left: 1px solid gray;
  height: inherit;
}


.container-vehicle-cell.inactive {
  background-color: #e0e0e0;
}

.container-vehicle-row__vehicle__service
{
  font-weight: bold;
  font-size: 16px;
  padding-left: 3px;
}

.container-vehicle-row__vehicle__time {
  font-size: 14px;
  padding-left: 3px;
}

.container-vehicle-row__vehicle__name
{
  font-size: 12px;
  padding-left: 3px;
  font-weight: normal;

}

.workerSlots {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  height: 100%;
  min-height: 40px;
}

.filler {
  flex-grow: 1;
  flex-shrink: 1;
}

.workerSlots.dropHighlight {
  border: 2px solid orange;
  padding: 3px;
}




.em {
  .container-vehicle-service-top {
    .category {
      background-color: #b0da70;
      color: #000;
      border-color: #90aa60;
    }
  }
}

.emplus {
  .container-vehicle-service-top {
    .category {
      background-color: #ffca8c;
      color: #000;
      border-color: #d0aa60;
    }
  }
}

.intrah {
  .container-vehicle-service-top {
    .category {
      background-color: #F091EE;
      color: #000;
      border-color: #D071CE;
    }
  }
}

.interh {
  .container-vehicle-service-top {
    .category {
      background-color: #39F;
      border-color: #17D;
      color: #fff;
    }
  }
}

.moss {
  .container-vehicle-service-top {
    .category {
      background-color: #666;
      color: #FFF;
      border-color: #666;
    }
  }
}

.activeHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;

  .counter {
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
    border-radius: 12px;
    background-color: #303030;
    color: white;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@import '../../../../style/global';

$em_plus: #ffca8c;
$em_plus_background: #fff2e8;
$em: #b0da72;
$em_background: #ebf6da;
$interh: #09a7ef;
$interh_background: #c6ecfd;
$intrah: #e8f3ff;
$moss: rgba(223, 88, 88, 0.2);

.DailyServiceCell{
  position: relative;
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  /*align-items: end;*/
  justify-content: start;
  height: 100%;


  &-error{
    position: absolute;
    top:22px;
    right:10px;
  }

  &-serviceTypeContainer{
  }

  &-serviceType{
    padding: 1px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-right:3px;
  }

  &-serviceType.EM_PLUS {
    background: $em_plus_background;
    color: $em_plus;
    border: solid 1px $em_plus;
  }

  &-serviceType.EM {
    background: $em_background;
    color: $em;
    border: solid 1px $em;
  }

  &-serviceType.INTERH {
    background: $interh_background;
    color: $interh;
    border: solid 1px $interh;
  }

  &-serviceType.INTRAH {
    background: $intrah;
    color: $font-dark-color2;
    border: solid 1px $font-dark-color2;
  }

  &-serviceType.MOSS {
    background: $moss;
    border: solid 1px $error-color;
    color: $error-color;
  }

  &-serviceName{
    font-size: 16px;
    font-weight: 500;
    color: $font-dark-color2;
  }

  &-serviceName{
    padding-top: 2px;
    font-size: 16px;
    font-weight: 500;
    color: $font-dark-color2;
  }
  &-need {

    .warning{
      /*font-weight: bold;*/
      color: #ffca8c;
      .IconWorker, .IconVehicle{
        path {
          fill: #ffca8c;
        }
      }
    }

    .error{
      /*font-weight: bold;*/
      color: $error-color;
      .IconWorker, .IconVehicle{
        path {
          fill: $error-color;
        }
      }
    }

    span:first-child {
      margin-right: 20px;
    }
    .IconVehicle{
      margin-right:3px;
    }
  }
}

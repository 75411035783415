@import 'src/style/global';

.ConfirmModal {
  .plain-list {
    margin: 0;
    padding: 0;
  }
  .plain-list li {
    border-top: 1px solid $color-pale-grey;
    border-bottom: 1px solid $color-pale-grey;
    padding: 10px 0;
    list-style: none;
  }
}

.TimeLineSeparatorsHorizontal{

    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    &-line {
      position: absolute;
      height: 100%;
      border-right: 1px solid #ccc;
      top: 0;
      z-index: 4;

      &:first-child {
        border-right: none;
      }
    }
}

@import 'src/style/global';

.FixedShiftModal {

  max-width: 410px;

  .form {

    .addEmptyContract {
      cursor: pointer;
      color: $primary-color;
    }

    .Select select{
      padding: 10px 16px;
    }

    .row-inner{
      display:flex;
      align-items:center;
      margin-bottom:20px;
    }

    .row {
      width: 100%;
      margin: 5px 0 5px 0;

      .Select {
        float:left;
        width: 25%;
        margin: 0 0 0 20px;
      }

      .Button {
        /*float:left;*/
        margin: 0 2px 0 2px;
        background: $color-light-blue;
      }

      .selected {
        background: $primary-color;
        color: black;
      }

      .selected:hover {
        background: $primary-color;
        color: white;
      }


      .close {
        float: left;
        margin: 10px 0 0 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .bottom-row {
      float:left;
      padding: 5% 0 0 0;
    }

    .row-1 {
      .row-w {
        width: 47%;
      }

      .row-w:nth-child(2) {
        margin-left: 20px;
      }

      .height {
        padding: 2px;
      }
    }

    .row-2 {
      width: 100%;

      .row-w {
        width: 20%;
      }

      .divider {
        width: 5%;
        margin-top: 45px;
        margin-left: 10px;
      }

      .column-2 {
        margin-left: 20px;
        width: 45%;
      }
    }

    .row-3 {
      width: 100%;
      display: flex;
      flex-direction: row;

      .row-w {
        width: 47%;
        margin:0 15px 0 0;
      }
    }
  }
}

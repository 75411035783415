@import 'src/style/global';

.InformationModal {
  background: white;
  padding: 17px 44px;
  border-radius: 8px;
  z-index: 1001;
  @include modalWidth();

  h1{
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  &-Body{
    min-height: 280px;
  }

  &-Buttons{
    float: right;
    margin-top:20px;
    justify-content: space-between;
  }
}

.InformationModal-Overlay{
  position: fixed;
  top:0;
  bottom: 0;
  left:0;
  right:0;
  z-index:1000;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

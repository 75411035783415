@import '../../style/global';

.Table{
  border-collapse: collapse;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;

  thead{
    tr{
      height: 40px;
      border-bottom:1px solid #dae4f0;
      th{
        font-size: 14px;
        color: #8da1b0;
        text-transform: uppercase;
        font-weight: normal;
        padding:0 20px;
        flex-direction: row;
        text-align: left;
      }
    }
  }

  tbody{
    tr{
      height: 40px;
      background-color: #ffffff;
      border-bottom:1px solid #dae4f0;
      &:last-child{
        border-bottom:none;
      }
      td{
        padding:0 20px;
        font-size: 14px;
        color: #424d54;
        flex-direction: row;
      }
      &:hover{
        background-color: #f8fbff;
      }
    }
  }
}

.hide-cell-below-big-desktop,
.hide-cell-below-desktop,
.hide-cell-below-tablet-landscape,
.hide-cell-below-tablet-portrait,
.show-cell-phone-only{
  display: none;
}

@include for-phone-only(){
  .show-cell-phone-only{
    display: table-cell;
  }
}

@include for-tablet-portrait-up(){
  .hide-cell-below-tablet-portrait{
    display: table-cell;
  }
}
@include for-tablet-landscape-up(){
  .hide-cell-below-tablet-landscape{
    display: table-cell;
  }
}
@include for-desktop-up(){
  .hide-cell-below-desktop{
    display: table-cell;
  }
}

@include for-big-desktop-up(){
  .hide-cell-below-big-desktop{
    display: table-cell;
  }
}

@import '../../../../style/global';

.DailyVehicleCell {
  padding: 5px 5px;
  height: 100%;

  &-machineType {
    float: left;
    padding: 1px 5px;
    border-radius: 4px;
    border: solid 1px #b0da72;
    background-color: #ebf6da;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #b0da72;


  }

  &-machineName {
    font-size: 16px;
    font-weight: 500;
    width: 80%;
    float: left;
    color: $font-dark-color2;

    .IconVehicle {
      float:left;
      margin: 0 10px 0 0;
    }

    .name {
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .skillLabel {
      float: left;
    }
    .clear {
      clear:both;
    }
  }

  .KebabIcon {
    float:right;
  }
}

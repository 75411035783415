.TimeLineSeparatorVertical {
  position: absolute;
  height: 100%;
  border-right: 1px solid #ccc;
  top: 0;

  &:first-child {
    border-right: none;
  }
}

@import "../../style/global";

.SettingModal {

  margin-top: 25px;

  .above-title {
    width: 100%;
    min-height: 2%;
    /*float: left;*/
    color: $primary-color;
    cursor: pointer;

    * {
      /*float: left;*/
      margin: 10px 0;
    }
  }

  .up-title {
    width: 40%;
  }

  .form-content {
    background: white;
    /*float: left;*/
    width: 100%;
    margin: 15px 0 0 0;
    border-radius: 5px;
    padding-bottom: 15px;

    .form {
      width: 95%;
      padding: 0 5%;
      margin: 3% 0;

      h2 {
        margin-top: 3%;
      }
    }
  }

  .top-row {
    display: flex;
    flex-direction: row;
    margin: 2% 0;
    flex-wrap: wrap;

    .TooltipContainer {
      /*float: left;*/
      margin-top: 12px;
    }

    .other-turns{
      select {
        height: 40px;
        padding: 8px;
      }
    }

/*    .other-turns input[type="text"] {
      background: white;
      -moz-appearance: textfield;
    }

    .other-turns input[type="text"]::-webkit-inner-spin-button,
    .other-turns input[type="text"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }*/

    .control {
      width: 25%;
      min-width: 200px;

      input, select {
        width: auto;
        max-width: 120px;
        float: left;

      }

      label {
        float: left;
        margin: 10px 10px;
        color: $text-color;
      }

      .height select {
        padding-top: 2px;
        padding-bottom: 2px;
        width: 100%;
        margin: 0;
      }

      .TextInput-label {
        width: 100%;
      }

      .react-date-picker {
        width: 100%;
      }
    }
  }

  // Multi-tab

  .react-tabs__tab {
    color: #b2c2cd;
  }

  .react-tabs__tab--selected {
    color: black;
  }

}

@import 'src/style/global';

.RadioRegistry {
  background: white;
  padding: 17px 44px;
  @include modalWidth();
  border-radius: 8px;
  z-index: 1001;
  min-height: 200px;

  h2 {
    @include big-medium-dark-text;
  }

  h3 {
    @include default-text();
  }

  .RadioRegistry-Body {
    min-height: 200px;
  }

  h1 {
    color: #4b5760;
    font-size: 22px;
    text-align: left;
    width: 100%;
  }

  &-Buttons {
    margin-top: 20px;
    float: right;

    button {
      margin: 0 0 0 15px;
    }
  }
}

.RadioRegistry-Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

@import '../../../style/global';

.EngineErrorsModal{
  z-index: 11;
  &-title{
    margin-top: 11px;
    font-family: Roboto, serif;
    color: $font-dark-color2;
    font-size: 16px;
    font-weight: 600;
  }

  &-message{
    margin-top: 11px;
    max-height: 60vh;
    overflow:auto;
    background: $background-color;
  }
  &-buttons{
    display: flex;
    justify-content: space-between;
    .Button{
      margin:20px 10px;
    }
  }
}

@import '../../../../style/global';

.MonthlyEmployeeCell{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $font-dark-color2;
  height: 100%;
  padding: 5px;
  justify-content: space-between;

  &-name{
    max-width: 120px;
    white-space: break-spaces;
    text-overflow: ellipsis;
  }

  @include for-tablet-portrait-up {
    padding: 20px 10px 20px 20px;

    &-name {
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-totalHours{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #60707b;
    background-color: #f8fbff;
    border-radius: 8px;
    padding: 3px 7px;
    /*border: 1px solid #60707b;*/
  }

  .IconError {
    cursor: pointer;
  }
}

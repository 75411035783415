@import '../../../style/global';

.MonthlyPlanningConvergenceCompleted{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 70vh;
  background-color: #ffffff;

  &-wrapper{
    width: 100%;
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  &-workers{
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgb(141 161 176 / 32%);
    max-height: $max-height-timeline;
    overflow: auto;
    min-width: 400px;
    list-style-type: none;
    padding: 0;
    color: $text-color;
    li{
      padding: 15px;
      list-style-type: none;
      border-bottom: 1px solid #dae4f0;
    }
    li:last-child{
      border-bottom: none;
    }
  }

  &-title{
    @include bigger-bold-dark-text();
    margin: 10px;
    text-align: center;
  }

  &-subtext{
    margin: 10px;
    text-align: center;
  }

  &-separator{
    flex-grow: 0.5;
  }

  &-actionBar {
    .Button{
      margin: 40px;
    }
  }
}


@import "../../style/global";

.RadioCodeRegistry {
  .up-controls {
    margin: 0 0 20px 0;
  }

  .search-location {

    .circuit-filter{
      width: 17.5%;
      float: left;
      margin-top: 10px;
      height: 40px;
      select {
        padding: 8px;
        background-color: white;
      }
    }
    .search-input{
      width: 80%;
      float: left;
      margin: 0 0 0 2.5%;

    }
  }

}
